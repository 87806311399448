<template>
  <div class="h-100">
    <b-form-group
      class="wameed-input text-book-16 text-font-main"
      :class="fieldClasses"
      :label="label"
      :label-for="id"
    >
      <validation-provider
        #default="{ errors }"
        :name="name"
        :vid="name"
        :rules="rules"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid' : null"
        >
          <v-select
            @input="updateValue($event)"
            :value="value"
            :dir="'rtl'"
            :label="title"
            :multiple="multiple"
            :name="id"
            :class="classes"
            :options="items"
          >
            <template
             v-if="multiple"
              #selected-option-container="{option}"
            >
              <div class="vs__selected" v-if="value && value.findIndex((item) => item.id == option.id)==0" >{{ option[title] }}</div>
              <div class="vs__selected"  v-if=" value && value.findIndex((item) => item.id == option.id)==1" >{{ '+'+(value.length-1) }}</div>
            </template>
            <template v-if="multiple" v-slot:option="option">
              <b-form-checkbox
                class="custom-control-main  "
                :class="
                  value && value.findIndex((item) => item.id == option.id) > -1
                    ? 'active'
                    : ''
                "
                :checked="
                  value && value.findIndex((item) => item.id == option.id) > -1
                "
              >
                <span class="text-book-16 text-font-sub mx-2">{{
                  option[title]
                }}</span>
              </b-form-checkbox>
            </template>
          </v-select>

          <!-- <b-dropdown
            :id="id"
            :disabled="disabled"
            :class="classes"
            :variant="variant"
            :text="title"
            class="dropdown-icon-wrapper"
          >
            <template #button-content>
              <div class="d-flex justify-content-between align-items-center">
                <span class="mr-1" :class="valueClasses">{{ title }}</span>

                <arrow-down-icon />
              </div>
            </template>
            <b-dropdown-item
              variant="white"
              :class="item.value == title ? 'active' : ''"
              v-for="(item, i) in items"
              :key="i"
              @click="dropdownClick(item)"
            >
              <span class="text-medium-16 text-font-secondary">{{
                item[text]
              }}</span>
            </b-dropdown-item> -->
          <!-- </b-dropdown> -->
        </b-input-group>

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { required, email } from "@validations";
export default {
  props: {
    classes: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    activeItem: {
      type: String,
      default: null,
    },
    valueClasses: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    fieldClasses: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
  },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      // validation rules
      required,
    };
  },
  methods: {
    dropdownClick(item) {
      this.$emit("dropdownClick", item);
    },
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
