<template>
  <div class="wameed-dashboard-page-content">
    <section class="wameed-dashboard-page-content_body">
      <b-row class="no-gutters px-2 my-1">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="cursor-pointer wameed-card">
            <b-card-body
              class="position-relative py-5 justify-content-between px-5"
            >
              <wameed-form refs="" @submitAction="submitJob">
                <!-- form -->
                <template slot="form-content" slot-scope="{ invalid }">
                  <div class="d-flex flex-wrap py-4">
                    <b-col cols="12" md="6">
                      <text-input
                        id="jobIcon"
                        v-model="jobIcon"
                        :label="$t('job_image')"
                        inputClasses="text-medium-16 text-font-main"
                        name="jobIcon"
                        rules="required"
                        placeholder="----------------"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                        id="jobName"
                        v-model="jobName"
                        :label="$t('job_name')"
                        inputClasses="text-medium-16 text-font-main"
                        name="jobName"
                        rules="required"
                        placeholder="----------------"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <wameed-dropdown
                        v-if="getAllSections"
                        :label="$t('job_section')"
                        classes="  text-medium-16 w-100"
                        valueClasses="  text-medium-16  text-font-secondary"
                        title="title"
                        v-model="jobSection"
                        variant="disable"
                        :items="getAllSections"
                        text="title"
                        name="jobName"
                        id="job-section"
                        rules="required"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <wameed-dropdown
                        :label="$t('job_type')"
                        classes="  text-medium-16 w-100"
                        valueClasses="  text-medium-16  text-font-secondary"
                        title="title"
                        v-model="jobType"
                        variant="disable"
                        :items="jobTypes"
                        text="title"
                        name="jobName"
                        id="job-type"
                        rules="required"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <wameed-dropdown
                        v-if="getAllPlaces"
                        :label="$t('job_place')"
                        classes="  text-medium-16 w-100"
                        valueClasses="  text-medium-16  text-font-secondary"
                        title="name"
                        v-model="jobLocation"
                        variant="disable"
                        :items="getAllPlaces"
                        text="title"
                        name="jobName"
                        id="job-location"
                        rules="required"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                        id="jobSalary"
                        :label="$t('job_salary')"
                        v-model="jobSalary"
                        inputClasses="text-medium-16 text-font-main"
                        name="jobSalary"
                        placeholder="----------------"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <!-- <wameed-mulit-dropdown
                        label="الخبرة"
                        classes="  text-medium-16 w-100"
                        valueClasses="  text-medium-16  text-font-secondary "
                        title="----------------"
                        variant="disable"
                        :items="getAllExperience"
                        text="title"
                        v-model="jobExperiance"
                        name="jobName"
                        rules="required"
                      /> -->

                      <wameed-dropdown
                        v-if="getAllExperience"
                        :label="$t('experience')"
                        classes="  text-medium-16 w-100"
                        valueClasses="  text-medium-16  text-font-secondary"
                        title="title"
                        v-model="jobExperiance"
                        variant="disable"
                        :items="getAllExperience"
                        :multiple="true"
                        text="title"
                        name="jobName"
                        id="job-location"
                        rules="required"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <wameed-dropdown
                        :label="$t('application_end_date')"
                        classes="  text-medium-16 w-100"
                        valueClasses="  text-medium-16  text-font-secondary"
                        title="name"
                        v-model="jobEndDate"
                        variant="disable"
                        :items="endDates"
                        text="name"
                        name="jobName"
                        id="job-end-date"
                        rules="required"
                      />
                    </b-col>

                    <b-col cols="12">
                      <text-editor v-model="description" rules="required" />
                    </b-col>
                  </div>
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 pb-3">
                      <wameed-btn
                        classes="  text-medium-16 text-white"
                        :title="$t('save_changes')"
                        :disabled="invalid"
                        type="submit"
                        variant="main"
                      />
                    </div>
                    <div class="px-4 pb-3">
                      <wameed-btn
                        classes="  text-medium-16 text-font-secondary "
                        :title="$t('cancel')"
                        type="button"
                        variant="gray"
                        @submitAction="cancelJob()"
                      />
                    </div>
                  </div>
                </template>
              </wameed-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import WameedForm from "@/components/WameedForm.vue";

import TextInput from "@/components/TextInput.vue";
import WameedBtn from "@/components/WameedBtn.vue";
import WameedDropdown from "@/components/WameedDropdown.vue";
import WameedAsideModal from "@/components/WameedAsideModal.vue";
import WameedSwitch from "@/components/WameedSwitch.vue";
import { mapActions, mapGetters } from "vuex";
import TextEditor from "@/components/TextEditor.vue";
export default {
  components: {
    TextInput,
    WameedBtn,
    WameedDropdown,
    WameedAsideModal,
    WameedSwitch,
    WameedForm,
    TextEditor,
  },
  data(vm) {
    return {
      jobTypes: [
        { title: vm.$i18n.t("full_time"), id: 1 },
        { title: vm.$i18n.t("part_time"), id: 2 },
        { title: vm.$i18n.t("contract"), id: 3 },
        { title: vm.$i18n.t("freelancer"), id: 4 },
      ],
      endDates: [
        { name: "Month", id: 1 },
        { name: "Unlimited", id: 2 },
      ],
      search: "",
      jobEndDate: null,
      jobLocation: null,
      jobExperiance: null,
      jobSalary: null,
      jobSection: null,
      jobType: null,
      jobName: null,
      jobIcon: null,
      description: ``,
    };
  },

  computed: {
    ...mapGetters({
      getJobDetails: "getJobDetails",
      getAllExperience: "getAllExperience",
      getAllPlaces: "getAllPlaces",
      getAllSections: "getAllSections",
    }),
  },
  watch: {
    getJobDetails(newVal) {
      this.jobEndDate =
        newVal.end_date == "Month"
          ? { name: "Month", id: 1 }
          : { name: "Unlimited", id: 2 };
      this.jobLocation = newVal.place;
      this.jobExperiance = newVal.experience;
      this.jobSalary = newVal.salary;
      this.jobSection = newVal.section;

      if (newVal.type == 1)
        this.jobType = { title: this.$i18n.t("full_time"), id: 1 };
      if (newVal.type == 2)
        this.jobType = { title: this.$i18n.t("part_time"), id: 2 };
      if (newVal.type == 3)
        this.jobType = { title: this.$i18n.t("contract"), id: 3 };
      if (newVal.type == 4)
        this.jobType = { title: this.$i18n.t("freelancer"), id: 4 };
      
      this.jobName = newVal.name;
      this.jobIcon = newVal.image;
      this.description = newVal.description;
    },
  },
  created() {
    this.loadJobDetails(this.$router.currentRoute.params.id);
    this.loadExperience();
    this.loadPlaces();
    this.loadSections();
  },
  methods: {
    ...mapActions({
      loadJobDetails: "loadJobDetails",
      loadExperience: "loadExperience",
      loadPlaces: "loadPlaces",
      loadSections: "loadSections",
      updateJob: "updateJob",
    }),
    cancelJob() {
      this.$router.push({
        name: "job-details",
        params: {
          lang: this.$i18n.locale,
          id: this.$router.currentRoute.params.id,
        },
      });
    },
    submitJob() {
      var experience = [];
      this.jobExperiance.forEach((element) => {
        experience.push(element.id);
      });
      this.updateJob({
        jobId: this.$router.currentRoute.params.id,
        content: {
          name: this.jobName,
          image: this.jobIcon,
          section_id: this.jobSection.id,
          type: this.jobType.id,
          place: this.jobLocation.id,
          salary: this.jobSalary,
          end_date: this.jobEndDate.name,
          experience: experience,
          description: this.description,
        },
      });
    },
  },
};
</script>
 
